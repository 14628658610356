<template>
  <div class="page-wrap">
  <HeaderPart></HeaderPart>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
  <div class="main-content">
  <router-view/>
  </div>

  <FooterPart></FooterPart>
  </div>
</template>

<style>
.page-wrap{ min-height:100vh; margin:0; }

header{ min-height:50px; background:lightcyan; }
footer{ min-height:50px; background:PapayaWhip; }

/* Trick */
.page-wrap{
  display:flex;
  flex-direction:column;
}

footer{
  margin-top:auto;
}
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}
:root{
  --main-color: #0070c0;
  --second-color: #BF1613;
}
nav a.router-link-exact-active {
  color: #42b983;
}

h1, h2, h3, h4{
  color: var(--main-color)
}

.hero{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: left bottom 20%;
  min-height: 350px;
  padding-top: 1px;

}
</style>
<script>
import HeaderPart from "@/components/parts/HeaderPart";
import FooterPart from "@/components/parts/FooterPart";
export default {
  components: {FooterPart, HeaderPart}
}
</script>