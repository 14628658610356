<template>
  <div class=""></div>

  <footer class="bg-light">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <div class="">
          <img src="/img/cp/logo_BHI.png" alt="logo bh" />
          <img src="/img/cp/logo_starr.png" alt="logo starr" />
        </div>
        <div>
          <router-link to="/files">
            {{$t('footer.all_info')}}
          </router-link>
        </div>
      </div>

    </div>
  </footer>
  <!-- cookie warning toast -->
  <div class="fixed-bottom p-4">
    <div :class="{'toast':true, 'show': show,  'bg-light w-40 mw-40': true}" role="alert" data-autohide="false">
      <div class="toast-header">

        <strong class="me-auto"></strong>
        <small></small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click="save(0)"></button>
      </div>
      <div class="toast-body p-4 d-flex flex-column">
        <h4>{{ $t("cookies.title") }}</h4>
        <p>
          {{ $t("cookies.subtitle") }}
        </p>
        <div class="ml-auto">
<!--          <button type="button" class="btn btn-outline-light mr-3" id="btnDeny">-->
<!--            Deny-->
<!--          </button>-->
          <button type="button" class="btn btn-primary" @click="save(1)">
            {{ $t("cookies.button") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import CookieLaw from 'vue-cookie-law'
export default {
  name: "FooterPart",
  components: {

  },
  data: function (){
    return { show: false}
  },
  props: {
    product: String
  },
  methods: {
    save(val){
      localStorage.setItem('cookies', val);
      this.show = false;
    }
  },
  mounted() {
     this.show = !localStorage.getItem('cookies');
  }
}
</script>

<style scoped>
footer{
  border-top: 5px solid var(--main-color);
  /*background-color: var(--main-color);/*/

}
footer img{
  padding:
      20px;
  max-height: 130px;
  mix-blend-mode: darken;
}
a{
  text-decoration: none;
  color: var(--main-color);
}
</style>