<template>
  <header class="bg-light">
    <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <div class="">
        <h1><router-link to="/">{{ $t('website') }}</router-link></h1>
      </div>
      <div class="text-end p-3 d-flex flex-column align-items-end">
        <div class="assistance d-flex ">
          <img src="/img/cp/ec_logo_small.png" style="width: 50px; height: 55px" alt="assistance logo" />
          <div style="margin-left: 6px">
            <div>{{ $t('contact.assistance.title')}}</div>
            <div>
              <a :href="'tel:' + $t('contact.assistance.tel')">{{ $t('contact.assistance.tel')}}</a>
            </div>
          </div>
        </div>
        <div class="contact">
          <a :href="'mailto:' + $t('contact.email')">{{ $t('contact.email') }}</a><br>
          infolinka: <a :href="'tel' + $t('contact.tel')">{{ $t('contact.tel') }}</a><br>
        </div>
      </div>
    </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderPart",
  props: {
    product: String
  }

}
</script>

<style scoped>
  a{ text-decoration: none;
    color: var(--main-color);
  }
  header{
    border-bottom: 1px solid var(--main-color);
  }
  h1 a{
    text-decoration: none;
    color: var(--main-color);
  }

  .assistance{
    font-size: 1.3em;
    line-height: 1.1em;
    color: #ff6900;
  }
  .assistance a{
    margin-top: -10px;
    font-size: 1em;
    color: #ff6900;
  }
  .contact{
    margin-top: -5px;
    color: var(--main-color);
    font-size: 0.8em;
  }


</style>